import { styled } from "styled-components";
import { Title, Header, Line } from "../Resume/Resume";
import { forwardRef, useCallback, useEffect, useState } from "react";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import { useReorderProvider } from "../../../context/ReorderContext/provider";
import DrugstorePurchaseDistribution from "./DrugstorePurchaseDistribution";
import MedicinalVsNonMedicinalDistribution from "./MedicinalVsNonMedicinalDistribution";
import DailySpendingByDrugstore from "./DailySpendingByDrugstore";
import TopMedicinalProducts from "./TopMedicinalProducts";
import TopNonMedicinalProducts from "./TopNonMedicinalProducts";
import { useDataProvider } from "../../../context/provider";
import { Box, Button, Icon } from "@extendeal/vendors-design-system";

const Analysis = forwardRef(function Analisis(props, ref) {
  const {
    items,
    updateItems,
    isReordering,
    visibility,
    toggleWidgetVisibility,
  } = useReorderProvider();

  const onSortEnd = useCallback(
    (oldIndex: number, newIndex: number) => {
      const result = arrayMoveImmutable(items.analisis, oldIndex, newIndex);
      updateItems("analisis", result);
    },
    [items, updateItems]
  );

  return (
    <Container
      reorderstate={isReordering}
      isvisible={isReordering || visibility.analisis}
    >
      <Header>
        {isReordering ? (
          <>
            <TitleContainer>
              <SortableKnob>
                <Box>
                  <Icon as="drag" color="neutral-600" />
                </Box>
              </SortableKnob>
              <Title>Análisis de compra por droguería</Title>
            </TitleContainer>
            <Box>
              {" "}
              {visibility.analisis ? (
                <Button
                  backgroundColor={"semantic-information-100"}
                  p={"1"}
                  onClick={() => toggleWidgetVisibility("analisis")}
                >
                  <Icon as="eye" type="outline" />
                </Button>
              ) : (
                <Button
                  backgroundColor={"semantic-information-100"}
                  p={"1"}
                  onClick={() => toggleWidgetVisibility("analisis")}
                >
                  <Icon as="eyeOff" />
                </Button>
              )}
            </Box>
          </>
        ) : (
          <Title>Análisis de compra por droguería</Title>
        )}
      </Header>
      <Line></Line>

      <GridContainer onSortEnd={onSortEnd} allowDrag={!!isReordering}>
        <Shadow
          isvisible={isReordering}
          style={{ gridArea: items.analisis[0], height: "100%" }}
        >
          <SortableItem key={items.analisis[0]}>
            <Widget
              style={{ gridArea: items.analisis[0], height: "100%" }}
              isvisible={isReordering || visibility.distrib_purchase_per_dm}
            >
              <DrugstorePurchaseDistribution />
            </Widget>
          </SortableItem>
        </Shadow>
        <Shadow
          isvisible={isReordering}
          style={{ gridArea: items.analisis[1] }}
        >
          <SortableItem key={items.analisis[1]}>
            <Widget
              style={{ gridArea: items.analisis[1] }}
              isvisible={
                isReordering || visibility.distrib_purchase_meds_and_not_meds
              }
            >
              <MedicinalVsNonMedicinalDistribution />
            </Widget>
          </SortableItem>
        </Shadow>
        <Shadow
          isvisible={isReordering}
          style={{ gridArea: items.analisis[2] }}
        >
          <SortableItem key={items.analisis[2]}>
            <Widget
              style={{ width: "100%", gridArea: items.analisis[2] }}
              isvisible={isReordering || visibility.money_per_dm}
            >
              <DailySpendingByDrugstore />
            </Widget>
          </SortableItem>
        </Shadow>
        <Shadow
          isvisible={isReordering}
          style={{ gridArea: items.analisis[3] }}
        >
          <SortableItem key={items.analisis[3]}>
            <Widget
              style={{ gridArea: items.analisis[3] }}
              isvisible={isReordering || visibility.top_n_meds}
            >
              <TopMedicinalProducts />
            </Widget>
          </SortableItem>
        </Shadow>
        <Shadow
          isvisible={isReordering}
          style={{ gridArea: items.analisis[4] }}
        >
          <SortableItem key={items.analisis[4]}>
            <Widget
              style={{ gridArea: items.analisis[4] }}
              isvisible={isReordering || visibility.top_n_no_meds}
            >
              <TopNonMedicinalProducts />
            </Widget>
          </SortableItem>
        </Shadow>
      </GridContainer>
    </Container>
  );
});
export default Analysis;
const Shadow = styled.div<{
  isvisible: boolean;
}>`
  /* border: ${(props) =>
    props.isvisible
      ? "0.5px dashed var(--Semantic-Information-600, #1a66fc)"
      : "transparentize"}; */
  background: ${(props) =>
    props.isvisible
      ? "var(--Semantic-Information-50, #f2f7ff);"
      : "transparentize"};
  box-shadow: ${(props) =>
    props.isvisible
      ? "0px 1px 3px 0px rgba(64, 67, 91, 0.1)" +
        "," +
        "0px 1px 2px 0px rgba(64, 67, 91, 0.06)"
      : "transparentize"};
  border-radius: 8px;
`;
export const WidgetHeaderSubContainer = styled.div``;

export const WidgetHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const ChartContainer = styled.div`
  width: 100%;
  text-align: center;
`;

export const Widget = styled.div<{
  isvisible: boolean;
}>`
  /* margin: 10px 0px 0px 0px; */
  background: #fff;
  border-radius: 8px;
  padding: 20px 15px 10px 15px;
  box-shadow: 0px 1px 3px 0px rgba(64, 67, 91, 0.1),
    0px 1px 2px 0px rgba(64, 67, 91, 0.06);
  flex-direction: column;
  display: ${(props) => (props.isvisible ? "flex" : "none")};
`;

export const GridContainer = styled(SortableList)`
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  gap: 50px;
  grid-template-areas:
    "first second"
    "third third"
    "fourth fifth";
  margin-top: 20px;

  @media (max-width: 1080px) {
    gap: 20px;
  }
`;
const Container = styled.div<{
  isvisible: boolean;
  reorderstate: boolean;
}>`
  width: 100%;
  margin: 10px 0px 10px 0px;
  position: relative;
  padding: 0px 10px;
  background: ${(props) => (props.reorderstate ? "#C6D9FE" : "transparent")};
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  display: ${(props) => (props.isvisible ? "flex" : "none")};
`;
