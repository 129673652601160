import Exporting from "highcharts/modules/exporting";
import offlineExportingModule from "highcharts/modules/offline-exporting";
import Highcharts from "highcharts";
import exportDataModule from "highcharts/modules/export-data";
const getLocaleConfig = (lang: string) => {
  const locales = {
    "es-AR": { decimalPoint: ",", thousandsSep: "." }, // Argentina
    "es-MX": { decimalPoint: ".", thousandsSep: "," }, // México
    "es-CO": { decimalPoint: ",", thousandsSep: "." }, // Colombia
    "pt-BR": { decimalPoint: ",", thousandsSep: "." }, // Brasil
    "en-US": { decimalPoint: ".", thousandsSep: "," }, // Estados Unidos
    "es-ES": { decimalPoint: ",", thousandsSep: "." }, // España
  };

  return locales[lang] || { decimalPoint: ".", thousandsSep: "," };
};
const browserLanguage = navigator.language;

const localeConfig = getLocaleConfig(browserLanguage);

if (typeof Exporting === "function") {
  Exporting(Highcharts);
  offlineExportingModule(Highcharts);
  exportDataModule(Highcharts);
}
Highcharts.setOptions({
  lang: {
    viewFullscreen: "Ver en pantalla completa",
    printChart: "Imprimir gráfico",
    downloadPNG: "Descargar imagen PNG",
    downloadJPEG: "Descargar imagen JPEG",
    downloadPDF: "Descargar archivo PDF",
    downloadSVG: "Descargar imagen SVG",
    downloadCSV: "Descargar CSV",
    downloadXLS: "Descargar archivo XLS",
    viewData: "Ver tabla de datos",
    decimalPoint: localeConfig.decimalPoint,
    thousandsSep: localeConfig.thousandsSep,
  },
});
