import { SortableKnob } from "react-easy-sort";
import { useReorderProvider } from "../../../context/ReorderContext/provider";
import { useDataProvider } from "../../../context/provider";
import {
  WidgetTitleContainer,
  WidgetTitle,
  WidgetDescription,
} from "../Resume/Resume";
import {
  analisisValues,
  formatDateToYYYYMMDD,
  message,
} from "../../../utils/formats";
import { ChartContainer } from "./Analysis";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { DataMessage } from "../EfficientPurchasing/EfficientPurchasing";
import {
  Box,
  Button,
  Icon,
  Skeleton,
  Stack,
} from "@extendeal/vendors-design-system";

export default function MedicinalVsNonMedicinalDistribution() {
  const { state, isLoading } = useDataProvider();
  const { isReordering, visibility, toggleWidgetVisibility } =
    useReorderProvider();

  const medicinalTotalAmount = state.productsDistribution?.map(
    (p) => p.medicinal_amount
  );
  const nonMedicinalTotalAmount = state.productsDistribution?.map(
    (p) => p.non_medicinal_amount
  );
  const drugManufacturerNames: string[] = state.productsDistribution?.map(
    (p) => p.drug_manufacturer_name
  );

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <WidgetTitleContainer>
          {isReordering && (
            <SortableKnob>
              <Box>
                <Icon as="drag" color="neutral-600" />
              </Box>
            </SortableKnob>
          )}
          <Box borderRadius={"md"} backgroundColor={"secondary-50"} p={"1"}>
            <Icon as="storeFront" type="outline" color="neutral-800" />
          </Box>
          <WidgetTitle>
            {analisisValues.distrib_purchase_meds_and_not_meds.title}
          </WidgetTitle>
        </WidgetTitleContainer>
        {isReordering && (
          <Box>
            {" "}
            {visibility.distrib_purchase_meds_and_not_meds ? (
              <Button
                backgroundColor={"neutral-white"}
                p={"1"}
                onClick={() =>
                  toggleWidgetVisibility("distrib_purchase_meds_and_not_meds")
                }
              >
                <Icon as="eye" type="outline" />
              </Button>
            ) : (
              <Button
                backgroundColor={"neutral-white"}
                p={"1"}
                onClick={() =>
                  toggleWidgetVisibility("distrib_purchase_meds_and_not_meds")
                }
              >
                <Icon as="eyeOff" />
              </Button>
            )}
          </Box>
        )}
      </div>
      <WidgetDescription>
        {analisisValues.distrib_purchase_meds_and_not_meds.description}
      </WidgetDescription>
      {isLoading ? (
        <Stack alignItems={"center"} justifyContent="center">
          <Skeleton w={"100%"} h="400px" />
        </Stack>
      ) : (
        <ChartContainer>
          {state.productsDistribution?.length ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "column",
                },
                title: {
                  text: ".",
                  style: {
                    color: "white",
                  },
                },
                credits: {
                  enabled: false,
                },
                tooltip: {
                  shared: true,
                  useHTML: true,
                  formatter: function () {
                    let total = 0;
                    this.points.forEach((point) => {
                      total += point.y;
                    });

                    let s = "<strong>" + this.points[0].key + "</strong><br/>"; // Usa la fecha real
                    this.points.forEach((point) => {
                      s +=
                        point.series.name +
                        ": <b>$ " +
                        Highcharts.numberFormat(point.y, 2) +
                        "</b><br/>";
                    });
                    s +=
                      "Total: <b>$ " +
                      Highcharts.numberFormat(total, 2) +
                      "</b>"; // Formatea el total correctamente

                    return s;
                  },
                },
                xAxis: {
                  categories: drugManufacturerNames,
                  gridLineWidth: 1,
                  gridLineColor: "#e0e0e0",
                },
                yAxis: [
                  {
                    title: {
                      text: "Dinero gastado",
                      style: {
                        color: "#333333",
                        fontSize: 14,
                        fontWeight: 600,
                      },
                    },
                    gridLineWidth: 1,
                    gridLineColor: "#e0e0e0",
                    gridLineDashStyle: "Dot",
                  },
                ],
                plotOptions: {
                  column: {
                    stacking: "normal",
                  },
                },
                exporting: {
                  enabled: true,
                  filename: `${formatDateToYYYYMMDD(new Date())}_${
                    analisisValues.distrib_purchase_meds_and_not_meds.title
                  }`,
                  buttons: {
                    contextButton: {
                      menuItems: [
                        "viewFullscreen",
                        "printChart",
                        "downloadPNG",
                        "downloadJPEG",
                        "downloadPDF",
                        "downloadSVG",
                        "downloadXLS",
                      ],
                    },
                  },
                  csv: {
                    columnHeaderFormatter: (item) => {
                      return item.name || "Droguería";
                    },
                  },
                },
                series: [
                  {
                    name: "Medicinales - Dinero Gastado",
                    data: medicinalTotalAmount,
                    yAxis: 0,
                    color: "#0047FF",
                  },
                  {
                    name: "No medicinales - Dinero Gastado",
                    data: nonMedicinalTotalAmount,
                    yAxis: 0,
                    color: "#73CE00",
                  },
                ],
              }}
            />
          ) : (
            <DataMessage>{message.no_data}</DataMessage>
          )}
        </ChartContainer>
      )}
    </>
  );
}
